//AUTH - CHOOSE COMPANY ---------------------------------------------------------------------------- START

.auth__choose-company{
    & .btn-group .btn {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      border-radius: 40px 0;
      padding: 40px;
      width: 100%;
      min-width: 300px;

      @include media-breakpoint-down(md) {
        padding: 20px;
        min-width: 100%;
      }

      &.active{
        border: 2px solid $success;
      }

      & img{
        height: 150px;

        @include media-breakpoint-down(md) {
          height: 60px;
        }
      }
  }

}

.grecaptcha-badge {
  margin-bottom: 3rem;
}




//AUTH - CHOOSE COMPANY ---------------------------------------------------------------------------- END



//Landing Page 

.landing_page{
  background-image: url('/assets/images/landing_page_ar.png');
  height: 5306px;
  margin: -3rem;
  margin-left: -6rem;
  margin-top: -9rem;
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}