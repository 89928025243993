.video-call-page {
  padding: 100px 0;
  .video-description {
    width: 75%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .user {
    border-style: solid;
    border-color: rgba(75, 85, 99, 1);
    border-width: 1px;
    height: 500px;
    @include media-breakpoint-down(md) {
      height: 150px;
      width: 150px;
    }
  }
  .translator {
    border-style: solid;
    border-color: rgba(75, 85, 99, 1);
    border-width: 1px;
    height: 500px;
  }
  .user-col {
    @include media-breakpoint-down(md) {
      position: absolute;
      z-index: 1;
    }
  }
}
