.line{
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    background: $gray-300;
  }

}



.review {
  @include media-breakpoint-down(md) {
    .total-amount {
      width: 100%;
    }
    .price-table {
      .amount {
        grid-row-start: 1;
      }
    }
  }

  #code+div{
    position: absolute;
  }
}

.payment-options {

  // flex-wrap: wrap;

  &__item {
    display: flex;
    box-shadow: $box-shadow;
    border-radius: $border-radius-sm-custom;
    padding: $spacer;
    align-items: center;
    justify-content: center;
    border: 2px solid $white;
    width: 20%;
    height: 120px;
    margin: 1rem 2rem;
    &:hover,
    &:focus,
    &:active {
      box-shadow: $box-shadow-lg;
      cursor: pointer;
      transition: ease-in-out 0.2s;
    }
     & img{
      width: 80px;
     }

     @include media-breakpoint-down(md) {
      width: 100%;
      margin: 1rem 0;

      & img{
        width: 60px;
      }
     }
  }

  &__item.selected {
    border: 2px solid var(--bs-success);
  }
}

// HYPER PAY FORM STYLES OVERRIDE
.wpwl-hint,
.error {
  font-size: 13px;
  color: $danger;
  font-weight: bold;
  min-height: 20px;
}

.wpwl-button-pay {
  margin: 22px auto auto;
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: $mowaamah-green;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 8px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-color: $mowaamah-green;
  &:hover,
  &:focus,
  &:active {
    background-color: var(--bs-primary);
    color: white;
  }
}

.wpwl-button-pay:hover .bi.bi-chevron-right {
  transform: translate(15px);
  transition: transform 0.2s ease-in;
}

.wpwl-control-brand,
.wpwl-label-brand {
  display: none;
}

.wpwl-brand-card {
  float: left;
}

.wpwl-control {
  border: 1px solid $gray-100;
  border-radius: 8px;
  height: 50px;
}

.wpwl-group-cardNumber {
  body[dir="rtl"] & {
  direction: ltr;
  text-align: right !important;
  }
}

.wpwl-control-expiry {
  body[dir="rtl"] & {
    direction: ltr;
    text-align: right !important;
  }
}

.wpwl-label {
  font-size: 14px;
  font-weight: bold;
  color: $mowaamah-green;
  text-align: right;
}
.payment-successful-icon {
  width: 70px;
  height: 70px;
}

.order-successful-icon {
  width: 53px;
  height: 53px;
}

.status-icon{
  height: 70px;
  height: 70px;
}
