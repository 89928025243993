.mowaamah-sidebar {
  @extend .bg-primary, .h-100;
  padding: 74px 0 32px 0;
  @include media-breakpoint-down(lg) {
  }
  .nav-link {
    @extend .text-white, .fs-5, .px-4, .py-3, .d-flex, .align-items-center;
    &.active {
      background-color: $green-700;
    }
    i {
      @extend .fs-3, .d-flex, .align-items-center;

      html[lang=ar] &{
        @extend .ms-3;
      }

      html[lang=en] &{
        @extend .me-2;
      }
    }
  }
}
