.mowaamah-footer {
  @extend .w-100, .bg-white, .shadow;
  .mowaamah_logo img {
    height: $spacer * 3;
  }
  .mlsd_logo img {
    height: $spacer * 2.5;
  }
  .grid {
    @extend .align-items-center;
    --bs-columns: 25;
    --bs-gap: 0.5rem;
  }
  .social-media {
    grid-column-start: 21;
  }
  @include media-breakpoint-down(lg){
    .grid {
      @extend .align-items-center;
      --bs-columns: 26;
    }
    .mlsd_logo {
      margin-top: 5px;
    }
  }
  @include media-breakpoint-down(md) {
    .mowaamah_logo {
      grid-row-start: 4;
    }
    .grid {
      --bs-columns: 8;
      --bs-gap: 0rem;
      --bs-rows: 5;
    }
    .social-media {
      grid-column-start: auto;
    }
  }
}

.landing-footer{
  padding: 3rem 0;
  color: #fff;

  & ul{
    list-style: none;

    & li{
      margin-bottom: 0.5rem;

      & a{
        color: #fff;

        &:hover{
          color: #163040;
        }
      }
    }
  }

  & .copyright-section{
    @extend .mt-5, .pt-3, .justify-content-between;
    border-top: 1px solid rgba(255,255,255,0.2);
  }
}

.mowaamah-footer-logo{
  height: 70px;
}
