.faq-page{
    padding: 100px 0;

   & .accordion-item{

        & .accordion-button{
            @extend .d-flex, .position-relative, .w-100, .fs-4;
            padding: 30px 25px 30px;
            text-align: right;
            color: #204257;

            html[lang=en] &{
                padding: 30px 0 30px 25px;
                text-align: left;
            }

            &:before{
                content:'';
                width: 9px;
                height: 9px;
                background-color: #204257;
                position: absolute;
                right: 0;
                border-radius: 50%;
                html[lang=en] &{
                  left:0
                }
            }

            &:after{
                @extend .position-absolute;
                background-image: url('../../../assets/images/icon-down-arrow.svg');
                left: 0;

                html[lang=en] &{
                    left: auto;
                    right: 0;
                }


            }

            &:not(.collapsed){
                background-color: transparent;
                border-color: transparent;
                
                
            }

            &:focus{
                border-color: transparent;
                box-shadow: none;
                
                
            }


        }

        & .accordion-body{
            @extend .fs-5, .mb-3;
            color: #6F6F6F;

        }

    }
}