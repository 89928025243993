.contact-page {
    padding: 100px 0;
    body[dir="ltr"] & {
        .text-md-end {
            text-align: left !important;
        }
    }
    .sendMsg {
        body[dir="ltr"] & {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }
    }
    & button {
        width: auto;
        min-width: 150px;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 40px;
        }
    }
    & a {
        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
            & button {
                margin-top: 0px;
            }
        }
    }
}