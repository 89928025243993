.privacy-policy {
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  h1 {
    margin-bottom: 20px;
  }

  h4 {
    color: #343a40;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  b{
    font-weight: bold;
    font-size: 18px;
  }
  p, ul, li {
    font-size: 16px;
    line-height: 1.6;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-left: 20px;
    padding-left: 20px;
    list-style-type: disc;

    li {
      margin-bottom: 10px;
    }
  }

  .text-justify {
    text-align: justify;
  }

  @media (max-width: 768px) {
    padding: 15px;
    h1 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
    }
    p, ul, li {
      font-size: 14px;
    }
  }

  
}