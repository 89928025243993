.mowaamah-home {
  @extend .my-5, .px-4, .px-lg-5;
  .company-info-container {
    @extend .pt-4;

    .company-name {
      color: $success;
    }

    .company-cert-status {
      justify-self: end;
      position: relative;

      & button {
        text-align: left;

        &:hover {
          cursor: default;
          // background: initial;
        }

        body[dir="ltr"] & {
          text-align: right;
        }
      }

      &__icon {
        width: 50px;
        position: absolute;
        left: -5%;

        body[dir="ltr"] & {
          right: -5%;
          left: auto;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;

        & button {
          text-align: center !important;
          padding-left: 1rem !important;
          padding-right: 1rem !important;

          body[dir="ltr"] & {
            text-align: center;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
          }
        }

        &__icon {
          left: -2px;

          body[dir="ltr"] & {
            right: -2px;
            left: auto;
          }
        }
      }
    }
  }

  body[dir="rtl"] &,
  html[lang="ar"] & {
    .pe-5 {
      padding-left: 3rem !important;
      padding-right: 1rem !important;
    }
  }

  // .certificate-container {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
  // .certificate-image {
  //   width: 615px;
  //   height: 474.03px;
  //   margin: 0 auto; /* Center the image horizontally */
  // }

  // .disabled-image {
  //   opacity: 0.5; /* Reduce opacity to create a muted effect */
  //   filter: grayscale(
  //     100%
  //   ); /* Apply grayscale filter for a grayed-out appearance */
  // }

  .steps-home {
    @extend .d-flex, .align-items-center, .justify-content-evenly, .mt-5;
    &-text {
      @extend .text-success,
        .fs-6,
        .fw-bold,
        .text-center,
        .w-100,
        .text-nowrap,
        .align-self-center;
    }

    & div.step-home:last-child > .step-image:after,
    & div.step-home:last-child > .step-image:before {
      display: none;
    }
  }

  .step-home {
    z-index: 0;
    @extend .d-flex, .flex-column, .align-items-center, .position-relative;
    width: calc(100% / 6);
    .step-image {
      height: 105px;
      @extend .d-flex, .align-items-center;
      img {
        min-height: 60px;
        min-width: 50px;
      }
    }
    .step-image:after {
      content: "";
      position: absolute;
      top: 39%;
      right: 70%;
      width: 100%;
      height: 4px;
      border-bottom: dashed 2px rgba(0, 0, 0, 0.596);
      z-index: -1;

      body[dir="ltr"] & {
        left: 70%;
        right: 0;
      }
    }

    .step-image:before {
      display: block;
      position: absolute;
      top: 30.5%;
      right: 90%;
      content: "\F22D";
      font-family: "bootstrap-icons";
      font-size: 19px;
      font-weight: bold;
      color: $orange;
      z-index: 2;

      body[dir="ltr"] & {
        content: "\F231";
        left: 90%;
        right: 0;
      }
    }

    &--completed:after {
      display: block;
      position: absolute;
      top: 25px;
      left: 50px;
      content: "";
      height: 4px;
      width: 100%;
      border-bottom: dashed 2px rgba(0, 0, 0, 0.596);
      z-index: -1;
    }
  }
  .certificate-btn,
  .training-btn {
    &__get {
      grid-column-start: 6;
    }
    &__view {
      grid-column-start: 10;
    }
  }
  @include media-breakpoint-between(md, xl) {
    .step-home {
      .step-image::before {
        top: 22px;
        margin: 0 auto;
        font-size: 12px;
        left: 100%;
      }
      .step-image::after {
        top: 28px;
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .step-home {
      .step-image {
        height: 55px;
        img {
          min-height: 45px;
          min-width: 35px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .steps-home {
      flex-direction: column;
      align-items: center !important;
      margin-top: 0 !important;

      &-text {
        padding-right: 30px;
        text-align: right !important;

        body[dir="ltr"] & {
          padding-left: 30px;
          padding-right: 0;
          text-align: left !important;
        }
      }
    }
    .step-home {
      flex-direction: row !important;
      align-items: flex-start !important;
      width: 100%;
      padding-top: 36px;
      .step-image {
        width: 45px;
        height: auto;
        flex: none;
      }
      .step-image:after {
        top: 100%;
        right: 22px;
        height: 55px;
        width: 0px;
        border-right: dashed 2px rgba(0, 0, 0, 0.596);
        border-bottom: none;

        body[dir="ltr"] & {
          left: 22px;
          right: 0;
        }
      }

      .step-image:before {
        top: 110%;
        right: 17px;
        content: "\F229";
        font-size: 12px;
        font-weight: bold;
        color: $orange;

        body[dir="ltr"] & {
          content: "\F229";
          left: 17px;
          right: 0;
        }
      }
    }
    .certificate-btn,
    .training-btn {
      &__get {
        grid-column-start: 1;
      }
      &__view {
        grid-column-start: 1;
      }
    }
  }
}

.certificate-badge.btn-outline-dark:hover {
  cursor: default;
  color: $dark !important;
  background: $white !important;
}

.certificate-badge.btn-outline-success {
  cursor: default;
  color: $success !important;
  background: $white !important;
}

.workshop-modal {
  .workshop-btn {
    width: 100%;
  }

  .workshop-btn-outline {
    color: $secondary;
    &:hover {
      color: $secondary;
      opacity: 70%;
    }
  }

  .modal-content {
    border-radius: 50px 0px;
  }
}
