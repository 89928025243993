.about {
    //HERO SECTION ------------------------ START
    .about-hero {
        @extend .position-relative;
        & h1 {
            font-weight: 700;
        }
        & p {
            font-size: 24px;
        }
        body[dir="ltr"] & .justify-content-start {
            justify-content: flex-end !important;
        }
        padding: 9rem 0;
        & .about-hero-img {
            @extend .position-absolute,
            .z-n1;
            top: 0;
            left: 0;
            max-width: 800px;
        }
        @include media-breakpoint-down(xl) {
            padding: 21rem 0 5rem 0;
            .about-hero-img {
                width: 100%;
                max-width: 500px;
            }
            &:after {
                content: '';
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.8);
            }
        }
        @include media-breakpoint-down(sm) {
            .about-hero-img {
                height: auto;
                width: 98%;
            }
        }
    }
    //HERO SECTION ------------------------ END
    //
    //
    //GOALS SECTION ------------------------ START
    .goals {
        @extend .position-relative,
        .z-0;
        background-position: left;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        background-image: url("/assets/images/hero-image.jpg");
        padding: 6rem 0;
        &:after {
            @extend .position-absolute,
            .z-n1;
            background: #204257;
            opacity: 0.85;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
        }
        & .goal-card {
            @extend .text-center,
            .d-flex,
            .flex-column;
            background: rgba(0, 0, 0, 0.4);
            padding: 40px;
            height: 100%;
            border-radius: 50px 0;
            align-items: center;
        }
        & img {
            max-height: 90px;
            margin-bottom: 40px;
            display: inline-flex;
            min-width: none;
        }
        & p {
            font-size: 24px;
        }
    }
    //GOALS SECTION ------------------------ END
    //
    //
    //STANDARDS SECTION ------------------------ START
    .about-standards {
        padding: 6rem 0 3rem 0;
        background: transparent linear-gradient(236deg, #204257 0%, #146E71 46%, #204257 100%) 0% 0% no-repeat;
        & .card {
            box-shadow: 0px 0px 30px #673B001F;
            border: 2px solid #F7B318;
            border-radius: 45px 0px;
            background: transparent;
            height: 100%;
            padding: 90px 30px 30px 30px;
        }
        & figure {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: -80px;
            border-radius: 50%;
            background: $secondary;
            width: 140px;
            height: 140px;
            margin-bottom: 40px;
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
                height: 74px;
            }
        }
        & h3 {
            @extend .text-white,
            .text-center,
            .fs-4,
            .mb-4;
            font-weight: 700;
        }
        & p {
            @extend .text-white,
            .text-center,
            .fs-5;
        }
        & .col-12 {
            margin-bottom: 5rem;
        }
    }
    //STANDARDS SECTION ------------------------ END
    //
    //
    //CLASSIFICATION SECTION ------------------------ START
    .about-classification {
        padding: 8rem 4rem;
        & img {
            max-width: 100%;
        }
        @include media-breakpoint-down(xl) {
            padding: 3rem 0;
        }
    }
    // CLASSIFICATION SECTION ------------------------ END
    //
    //
    //REGISTER SECTION ------------------------ START
    .about-register {
        @extend .position-relative;
        padding: 10rem 0;
        background: url('../../../assets/images/hand-photo.png');
        background-repeat: no-repeat;
        background-position: right;
        & button {
            min-width: 150px;
        }
        body[dir="ltr"] & .justify-content-end {
            justify-content: flex-start !important;
        }
        @include media-breakpoint-down(xl) {
            background-position: left;
            &:after {
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.7);
                content: '';
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0
            }
            & h2 {
                z-index: 10;
            }
            & button {
                z-index: 10;
            }
        }
    }
    //REGISTER SECTION ------------------------ END
    //
    //
}