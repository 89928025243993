// FORM ------------------------------------------------------------------- START

$form-select-bg-position: left 0.75rem center;

.form-label {
  color: $form-label-color;
  font-weight: $form-label-font-weight;
  margin-bottom: $form-label-margin-bottom;
}

.form-control {
  color: $mowaamah-gray;
  // border: 1px solid $mowaamah-gray-light;
  border-radius: $border-radius-sm;
  padding: $input-padding-y $input-padding-x;

  &:focus {
    box-shadow: 0 0 0 0.25rem rgb(56 173 115 / 25%);
  }
  &.isInvalid {
    border-width: 1px;
    border-color: $danger;
    border-style: solid;

    & + span {
      border-color: $danger;
      border-style: solid;
      border-width: 1px 0 1px 1px;
    }
  }

  &.input--mobile {
    border-left-color: transparent;
    border-left-width: 0;
  }
}

.form-select {
  color: $mowaamah-gray;
  background-image: $form-select-indicator;
  background-position: $form-select-bg-position;
  // border: 1px solid $mowaamah-gray-light;
  border-radius: $border-radius-sm;
  background-size: $form-select-bg-size;
  padding: $input-padding-y $input-padding-x;

  &.isInvalid {
    border: 1px solid $red;
  }

  body[dir="ltr"] & {
    background-position: right center;
  }
}

.filter {
  &__control {
    &--is-focused {
      border: 1px solid rgb(56 173 115) !important;
      box-shadow: 0 0 0 0.25rem rgb(56 173 115 / 25%) !important;
    }
  }
  &__option {
    &--is-focused {
      background-color: rgb(56 173 115 / 25%) !important;
    }
    &--is-selected {
      background-color: #157f83bf !important;
    }
  }
}

.search-select {
  &.isInvalid {
    border: 1px solid $red;
    border-radius: 4px;
  }
}

// CHECKBOX AND RADIO

// The question or statement before checkbox or radio button options
.question,
.options {
  max-width: 700px;
  padding: 10px;
}

.form-check-input:checked[type="radio"] {
  background-image: url("../../assets/images/radio.svg");
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("../../assets/images/checkbox.svg");
}

.form-check {
  padding-left: 0;
  padding-right: 1.5rem;
  margin-right: 0;

  &:first-of-type {
    padding-right: 0;
  }

  html[lang="ar"] & {
    direction: rtl;
  }

  .form-check-input {
    margin-top: 0;
    margin-right: 0;
    margin-left: 1rem;
    html[lang="en"] & {
      margin-right: 1rem;
      margin-left: 0rem;
    }
    &:checked {
      background-color: $color-contrast-light;
      border-color: $mowaamah-gray;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgb(247 149 0 / 25%);
    }

    &.isInvalid {
      border-width: 1px;
      border-color: $danger;
      border-style: solid;
    }
  }

  &-inline {
    display: flex;
    align-items: center;
  }

  &-label {
    color: $mowaamah-gray;
    white-space: nowrap;
  }
}

// FILE UPLOAD
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 16px;
  min-width: 40px;
  padding-left: 20px;
}

.disabledInput:disabled {
  background: #fff;
}
.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #00000015;
  border-radius: 15px;
}
// INPUT GROUP // MOBILE FIELD
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: right;

  body[dir="ltr"] & {
    text-align: left;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  margin-right: -1px;
  border-top-left-radius: $border-radius-sm;
  border-bottom-left-radius: $border-radius-sm;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  direction: ltr;
}

.input-group-text {
  // border-color: $mowaamah-gray-light;
}

// FORM ------------------------------------------------------------------- END
