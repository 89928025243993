// Typography
//
// Font, line-height, and color for body text, headings, and more.

@font-face {
  font-family: "HRSD Regular";
  src: url("../fonts/HRSD-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HRSD Medium";
  src: url("../fonts/HRSD-Medium.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HRSD Bold";
  src: url("../fonts/HRSD-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

$mowaamah-font: "HRSD Regular", sans-serif;

// Custom Colors
$white: #ffffff;
$mowaamah-green: #158083;
$mowaamah-orange: #f19023;
$mowaamah-red: #ce0000;
$mowaamah-gray: #6a7575;
$mowaamah-gray-light: #f0f0f0;
$mowaamah-success: #38ad73;
$mowaamah-yellow: #f7b318;
$primary: $mowaamah-green;
$secondary: $mowaamah-orange;
$tertiary: $mowaamah-yellow;
$success: $mowaamah-success;

// Bootstrap theme-colors-map here
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $mowaamah-green,
    "secondary": $mowaamah-orange,
    "tertiary": $mowaamah-yellow,
    "danger": $mowaamah-red,
    "success": $mowaamah-success,
    "dark": $mowaamah-gray,
  ),
  $theme-colors
);

// Bootstrap color-variables here
$body-bg: $white;
$font-family-base: $mowaamah-font;
$body-font-family: $mowaamah-font;
$body-color: $mowaamah-gray;
$green: $mowaamah-green;
$orange: $mowaamah-orange;
$btn-color: $white;
$gray-100: #f5f5f5;
$gray-600: #707070;
$headings-color: $mowaamah-green;

// LINK color and apperarnce
$link-color: $mowaamah-green;
$link-hover-color: $mowaamah-success;
$link-decoration: none;

$enable-cssgrid: true;

$border-radius: 1.5rem;

// $card-border-radius: 40px 0px;
$card-border-width: 0px;
$tooltip-bg: $mowaamah-green;
$border-radius-sm-custom: 25px 0px !default;
$border-radius-lg-custom: 40px 0px;

// BOX SHADOW
$mowaamah-shadow: 0px 0px 30px rgba(0, 0, 0, 0.14);
$shadow: $mowaamah-shadow;

// IMPORT BOOTSTRAP CSS
@import "node_modules/bootstrap/scss/bootstrap";

/* // Import css files */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// // App sass file imports will start from here .
@import "src/App.scss";
@import "src/assets/sass/style.scss";
@import "src/components/Layout/Header/header.scss";
@import "src/components/Layout/Footer/footer.scss";
@import "src/components/Layout/Sidebar/sidebar.scss";
@import "src/components/Home/home.scss";
@import "src/components/Certificate/certificate.scss";
@import "src/shared/ChevronBar/chevronbar.scss";
@import "src/shared/ProgressSteps/progressteps.scss";
@import "src/shared/FormElements/form.scss";
@import "src/shared/FilesList/fileList.scss";
@import "src/components/Payment/payment.scss";
@import "src/components/Entity/entity.scss";
@import "src/components/Certificate/Orders/order.scss";
@import "src/components/Training/TrainingOrders/trainingOrder.scss";
@import "src/components/Training/training.scss";
@import "react-toastify/dist/ReactToastify.css";
@import "src/components/Common/common.scss";
@import "src/components/Auth/auth.scss";
@import "src/components/Landing/main/main.scss";
@import "src/components/Landing/faq/faq.scss";
@import "src/components/Landing/contact/contact.scss";
@import "src/components/Landing/about/about.scss";
@import "src/shared/CookieConsent/consent.scss";
@import "src/components/Landing/privacy/privacy.scss";
@import "src/components/Workshop/workshop.scss";
@import "src/components/Landing/videocall/videocall.scss";
