// GLOBAL STYLES ------------------------------------------------------------------- START

:root {
  writing-mode: initial;
  direction: rtl;
  text-orientation: inherit;
  unicode-bidi: embed;
}

// BODY

//BUTTONS
.btn {
  border-radius: $border-radius-sm;
  font-size: $font-size-lg;
  &:hover {
    color: $white;
  }
  // UPLOAD BUTTON
  &--upload {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $secondary;
    color: $white;
    padding: 3px 30px;
    border-radius: $border-radius-sm;
    z-index: 0;
    cursor: pointer;

    &:after {
      font-style: normal !important;
      margin-right: 5px;
    }

    &:hover {
      background: lighten($secondary, 5%);
      color: $white;
    }

    & i {
      margin-top: 4px;
    }
  }

  &--outlined {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $white;
    color: $secondary;
    padding: 3px 30px;
    border-radius: $border-radius-sm;
    z-index: 0;
    border-color: $secondary;
    cursor: pointer;

    &:after {
      font-style: normal !important;
      margin-right: 5px;
    }

    &:hover {
      background: $secondary;
      color: $white;
    }

    & i {
      margin-top: 4px;
    }
  }
}

//HEADINGS
h1,
h2,
h3,
h4 {
  font-weight: bold;
}

//PHONE NUMBER FIELD
.phone-number {
  direction: ltr;
  text-align: right;
  body[dir=ltr] & {
    text-align: left;
  }
}

//HR - HORIZONTAL DIVIDER
hr {
  border-color: $gray-200;
  opacity: 1;
}
