.consent-modal{
    @extend .position-fixed ,.bg-white,.border-0;
    left: 50%;
    bottom: -4%;
    transform: translate(-50%,-50%) !important;
    box-shadow: 1px 10px 20px rgba(0,0,0,0.4);
    border-radius: 12px;
    z-index: 40;

    @include media-breakpoint-down(sm) {
        width: 90%;
      }
}