.mowaamah-header {
  @extend .bg-body, .shadow, .w-100;
  .navbar-brand {
    img {
      height: $spacer * 3;
      margin-right: 5rem;

      body[dir="rtl"] & {
        margin-left: 5rem;
        margin-right: 0;
      }

      @include media-breakpoint-down(lg) {
        margin-right: 0;

        body[dir="rtl"] & {
          margin-left: 0;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.home-nav button.navbar-toggler .navbar-toggler-icon {
    background-image: url(../../../assets/images/close-icon-white.svg);
  }

  &.home-nav button.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url(../../../assets/images/menu-icon-white.svg);
  }
  button.navbar-toggler .navbar-toggler-icon {
    background-image: url(../../../assets/images/close-icon.svg);
  }

  button.navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url(../../../assets/images/menu-icon.svg);
  }

  .nav-link {
    &:hover {
      color: #204257;
    }

    &.active {
      position: relative;
      color: $secondary !important;

      &:after {
        content: "";
        background-color: $secondary !important;
        position: absolute;
        bottom: -17px;
        width: 60%;
        height: 3px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .mowaamah-menu {
    body[dir="ltr"] & {
      @extend .end-0;
    }

    body[dir="rtl"] & {
      @extend .start-0;
    }

    .mobile-nav {
      @extend .flex-column;

      body[dir="ltr"] & {
        @extend .rounded-start-4;
      }

      body[dir="rtl"] & {
        @extend .rounded-end-4;
      }

      .navbar-text,
      .nav-link {
        @extend .fw-normal,
          .text-white,
          .ps-3,
          .border-bottom,
          .border-dark-subtle,
          .fs-3,
          .d-flex,
          .align-items-center;

        body[dir="rtl"] & {
          @extend .pe-3;
        }
      }
      .navbar-text:nth-child(1) {
        @extend .border-0, .py-5;
      }
      .nav-link:nth-last-child(1) {
        @extend .border-0;
      }
      .logout-btn {
        @extend .border-0, .py-5;

        body[dir="ltr"] & i {
          transform: scaleX(-1);
        }
      }
      .nav-link > i {
        @extend .fs-1, .me-3, .d-flex, .align-items-center;

        body[dir="rtl"] & {
          margin-right: 0 !important;
          margin-left: 1rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .home-nav {
      position: absolute;
      top: 100%;
      background: white !important;
      left: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      padding: 2rem;

      body[dir="ltr"] & {
        right: 0;
        left: auto;
      }
      & .nav-link {
        padding: 0.8rem 0;
        color: $primary !important;

        &.active {
          color: $secondary !important;
        }

        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .home-nav {
    @extend .flex-column;

      body[dir="ltr"] & {
        @extend .rounded-start-4;
      }

      body[dir="rtl"] & {
        @extend .rounded-end-4;
      }

  }

  & button {
    white-space: nowrap;
    
    @include media-breakpoint-down(md) {
      white-space: normal;
    }
  }

  .outer {
    background: #fff;
    padding: 3px 15px;
    border-radius: 20px;
    border: 1px solid #ddd;
    & i{
      font-size: small;
      margin-right: 3px;
    }
  }

  & .header-menu{
    border-radius: 15px !important;
    min-width: 160px !important;

    & .submenu{
      white-space: normal;
    }

    & ul{
      padding-inline-start: 0px;
      max-width: 160px; 

      & li{
        max-width: 160px; 
        
        &:hover {
          background-color: #f5f5f5;
          color: $white;
        }
      }
    }
  }
}
