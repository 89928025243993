.steps-flow-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
  width: 100%;
  .step-flow-text {
    width: 100%;
    margin-right: -40px;
    height: 60px;
    shape-outside: polygon(
      75% 0%,
      100% 50%,
      75% 100%,
      0% 100%,
      25% 50%,
      0% 0%
    );
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    -webkit-clip-path: polygon(
      75% 0%,
      100% 50%,
      75% 100%,
      0% 100%,
      25% 50%,
      0% 0%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: $white;
    overflow: hidden;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(xxl){
      margin-right: -30px;
    }

    @include media-breakpoint-down(xl){
      margin-right: -20px;
    }

    &:first-child {
      shape-outside: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
      -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 50%, 0% 0%);
      justify-content: flex-start;
      margin-left: 0;
      span {
        @extend .ms-4;
      }

    }
    &:last-child {
      shape-outside: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 25% 50%, 0% 0%);
      clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 25% 50%, 0% 0%);
      -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 25% 50%, 0% 0%);
      justify-content: flex-end;
      span {
        @extend .me-4;
      }
    }
    span {
      flex-basis: 50%;
      text-align: right;
    }
    &--current {
      background-color: var(--bs-secondary);
      @extend .fs-6;
    }
    &--uncompleted {
      background-color: $gray-100;
      color: $body-color;
      @extend .fs-6;
    }
    &--completed {
      position: relative;
      background-color: var(--bs-success);
      @extend .fs-6;
      &:before {
        display: block;
        position: absolute;
        left: 22%;
        content: "\F633";
        font-family: "bootstrap-icons";
        font-size: 25px;
        font-weight: bold;
        color: $white;
      }
    }

    html[lang=ar] &{

      shape-outside: polygon(
      100% 0%,
      90% 50%,
      100% 100%,
      20% 100%,
      10% 50%,
      20% 0%
    );
    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 20% 100%, 10% 50%, 20% 0%);
    -webkit-clip-path: polygon(
      100% 0%,
      90% 50%,
      100% 100%,
      20% 100%,
      10% 50%,
      20% 0%
    );

    &:first-child {
      shape-outside: polygon(20% 0%, 100% 1%, 100% 100%, 20% 100%, 10% 50%);
      clip-path: polygon(20% 0%, 100% 1%, 100% 100%, 20% 100%, 10% 50%);
      -webkit-clip-path: polygon(20% 0%, 100% 1%, 100% 100%, 20% 100%, 10% 50%);
      justify-content: flex-start;
      margin-right: 0;
      span {
        @extend .me-4;
      }
      @include media-breakpoint-down(md){
        margin-left: -6px;
      }
    }
    &:last-child {
      shape-outside: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
      clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
      -webkit-clip-path: polygon(
        100% 0%,
        90% 50%,
        100% 100%,
        0 100%,
        0% 50%,
        0 0
      );
      justify-content: flex-end;
      span {
        @extend .ms-4;
      }
    }


    }
  }

  @include media-breakpoint-down(md) {
    .step-flow-text {
      margin: 0 -6px;
      height: 30px;

      &--completed {
        &:before {
          right: 7%;
        }
      }
    }
  }
}
