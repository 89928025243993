.main {
    overflow-x: hidden;
    .hero {
        @extend .position-relative,
        .z-0;
        min-height: 800px;
        background-position: left;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        background-image: url("/assets/images/hero-image.jpg");
        clip-path: ellipse(98% 86% at 50% 1%);
        @include media-breakpoint-down(xl) {
            clip-path: ellipse(130% 86% at 50% 0%);
        }
        &:after {
            @extend .w-100,
            .position-absolute,
            .z-n1,
            .top-0;
            background: -webkit-linear-gradient( 90deg, rgba(21, 128, 131, 0.8) 0%, rgba(15, 30, 39, 0.8) 100%);
            background: linear-gradient( 90deg, rgba(21, 128, 131, 0.8) 0%, rgba(15, 30, 39, 0.8) 100%);
            height: 100%;
            content: "";
        }
    }
    .specialities {
        @extend .position-relative;
        margin-top: -19rem;
        padding-bottom: 16rem;
        @include media-breakpoint-down(xl) {
            margin-top: -2rem;
        }
        &:after {
            @extend .position-absolute,
            .z-n1;
            content: "";
            width: 200px;
            height: 200px;
            opacity: 0.1;
            border-radius: 60px 0px;
            left: 12%;
            bottom: -80px;
            background-color: #f7b318;
        }
        & .card {
            @extend .shadow,
            .h-100;
            border-radius: 50px 0;
            padding: 2rem;
            align-items: center;
            & img {
                @extend .mb-4;
                max-height: 80px;
                min-height: 80px;
                width: auto;
                max-width: 220px;
            }
        }
    }
    .main-steps {
        @extend .position-relative;
        margin-top: 5rem;
        padding: 0 0 8rem 0;
        background: linear-gradient(90deg, #173141 0%, #0d6e71 100%) 0% 0% no-repeat padding-box;
        &:after {
            @extend .position-absolute;
            left: -107px;
            bottom: -60px;
            width: 180px;
            height: 180px;
            border: 30px solid #f19023;
            border-radius: 50px 0px;
            opacity: 0.16;
            content: "";
        }
        .vision-card {
            margin-top: -10rem;
            background: #38ad73;
            margin-bottom: 10rem;
            padding: 5rem;
            @include media-breakpoint-down(xl) {
                padding: 3rem;
            }
            & img {
                max-height: 140px;
            }
        }
        .steps-main {
            @extend .d-flex,
            .align-items-center,
            .justify-content-evenly,
            .mt-5;
            &-text {
                @extend .text-tertiary,
                .fs-4,
                .fw-bold,
                .text-center,
                .w-100,
                .text-nowrap,
                .align-self-center;
            }
            & div.step-main:last-child>.step-image:after,
            & div.step-main:last-child>.step-image:before {
                display: none;
            }
        }
        .step-main {
            z-index: 0;
            @extend .d-flex,
            .flex-column,
            .align-items-center,
            .position-relative;
            width: calc(100% / 6);
            .step-image {
                height: 105px;
                @extend .d-flex,
                .align-items-center;
                img {
                    min-height: 60px;
                    min-width: 50px;
                }
            }
            .step-image:after {
                content: "";
                position: absolute;
                top: 39%;
                right: 70%;
                width: 100%;
                height: 4px;
                border-bottom: dashed 2px rgba(255, 255, 255, 0.596);
                z-index: -1;
                body[dir="ltr"] & {
                    left: 60%;
                    right: 0;
                }
            }
            .step-image:before {
                display: block;
                position: absolute;
                top: 30.5%;
                right: 90%;
                content: "\F22D";
                font-family: "bootstrap-icons";
                font-size: 19px;
                font-weight: bold;
                color: $orange;
                z-index: 2;
                body[dir="ltr"] & {
                    content: "\F231";
                    left: 90%;
                    right: 0;
                }
            }
            &--completed:after {
                display: block;
                position: absolute;
                top: 25px;
                left: 50px;
                content: "";
                height: 4px;
                width: 100%;
                border-bottom: dashed 2px rgba(0, 0, 0, 0.5);
                z-index: -1;
            }
        }
        .certificate-btn,
        .training-btn {
            &__get {
                grid-column-start: 6;
            }
            &__view {
                grid-column-start: 10;
            }
        }
        @include media-breakpoint-between(md,
        xl) {
            .step-main {
                .step-image::before {
                    top: 22px;
                    margin: 0 auto;
                    font-size: 12px;
                    left: 100%;
                }
                .step-image::after {
                    top: 28px;
                    width: 100%;
                }
            }
        }
        @include media-breakpoint-down(xl) {
            .step-main {
                .step-image {
                    height: 55px;
                    img {
                        min-height: 45px;
                        min-width: 35px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            .steps-main {
                flex-direction: column;
                align-items: center !important;
                margin-top: 0 !important;
                &-text {
                    padding-right: 30px;
                    text-align: right !important;
                    body[dir="ltr"] & {
                        padding-left: 30px;
                        padding-right: 0;
                        text-align: left !important;
                    }
                }
            }
            .step-main {
                flex-direction: row !important;
                align-items: flex-start !important;
                width: 100%;
                padding-top: 36px;
                .step-image {
                    width: 45px;
                    height: auto;
                    flex: none;
                }
                .step-image:after {
                    top: 100%;
                    right: 22px;
                    height: 55px;
                    width: 0px;
                    border-right: dashed 2px rgba(255, 255, 255, 0.5);
                    border-bottom: none;
                    body[dir="ltr"] & {
                        left: 22px;
                        right: 0;
                    }
                }
                .step-image:before {
                    top: 110%;
                    right: 17px;
                    content: "\F229";
                    font-size: 12px;
                    font-weight: bold;
                    color: $orange;
                    body[dir="ltr"] & {
                        content: "\F229";
                        left: 17px;
                        right: 0;
                    }
                }
            }
        }
    }
    .gold-certified {
        @extend .position-relative;
        &:after {
            @extend .position-absolute,
            .z-n1;
            content: "";
            width: 200px;
            height: 200px;
            opacity: 0.2;
            border-radius: 60px 0px;
            right: -120px;
            bottom: -60px;
            background-color: #f7b318;
        }
        &:before {
            @extend .position-absolute,
            .z-n1;
            content: "";
            width: 250px;
            height: 200px;
            opacity: 0.2;
            border: 12px solid #158083;
            border-radius: 50px 0px;
            opacity: 0.16;
            right: -30px;
            bottom: -120px;
        }
        & h2 {
            padding-right: 20px;
            body[dir="ltr"] & {
                padding-left: 20px;
                padding-right: 0;
            }
        }
        & .badge-icon {
            max-height: 90px;
        }
        & .card {
            height: 160px;
            width: 100%;
            min-width: 160px;
            & img {
                max-height: 70px !important;
            }
            @include media-breakpoint-up(xl) {
                width: 160px;
                & img {
                    max-height: 160px !important;
                }
            }
        }
        .slick-slider {
            position: relative;
            z-index: 30;
            // margin-top: -40px;
            overflow: hidden;
        }
        .slick-slide {
            >div {
                margin-inline: 15px;
            }
        }
        .slider-wrapper {
            margin-left: -315px;
            margin-right: -315px;
        }
    }
    .about {
        @extend .position-relative,
        .z-0;
        background-position: left;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        background-image: url("/assets/images/hearing-aid-photo.jpg");
        padding: 5rem 0;
        @include media-breakpoint-up(xl) {
            padding: 8rem 0;
        }
        &:after {
            @extend .w-100,
            .position-absolute,
            .z-n1,
            .top-0;
            background: -webkit-linear-gradient( 90deg, rgba(21, 128, 131, 0.8) 0%, rgba(15, 30, 39, 0.8) 100%);
            background: linear-gradient( 90deg, rgba(21, 128, 131, 0.8) 0%, rgba(15, 30, 39, 0.8) 100%);
            height: 100%;
            content: "";
        }
        & .card {
            background: rgba(0, 0, 0, 0.3);
            border-radius: 45px 0px;
            padding: 35px 50px;
        }
    }
}