// APP - This is the overall wrapper of everything appearing on the page

html{
  scroll-behavior: smooth;
}
.main-app-container {
  display: grid;
  .Toastify__toast-container {
    z-index: 99999999;

    @include media-breakpoint-down(sm) {
      width: 92vw;
    }

  }
  .main-header {
    grid-area: header;
    @extend .position-fixed, .top-0, .w-100;
    z-index: 9999;
  }
  .main-content {
    padding-top: 74px;

    @include media-breakpoint-up(lg) {
      grid-area: content;
      min-height: 95vh;

    }

    .main{
      margin-top: -74px;
    }
  }

  .content {
    @extend .py-5,
      .px-3,
      .px-lg-5,
      .d-flex,
      .flex-column,
      .justify-content-center;

      &>.container{
        @extend .mt-4, .mb-5;
      }
  }

  .main-sidebar {
    grid-area: sidebar;
    @extend .d-none, .d-lg-block;
  }
  .main-footer {
    grid-area: footer;
    // position: fixed;
    // left: 0;
    // bottom: 0;
    width: 100%;
  }
  grid-gap: 0;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 4fr;
  &.logged_in {
    grid-template-areas:
      "header  header"
      "sidebar content"
      "footer  footer";
  }
  &.logged_out {
    grid-template-areas:
      "header  header"
      "content content"
      "footer  footer";
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 4fr;
    &.logged_out,
    &.logged_in {
      grid-template-areas:
        "header"
        "content"
        "footer";
    }
    .main-content {
      overflow: initial;
      height: auto;
      min-height: 86vh;

      body[dir="ltr"] & {
        padding-right: 0;
      }

      body[dir="rtl"] & {
        padding-left: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .main-footer {
      position: relative;
      bottom: auto;
    }
  }

  .card {
    border-radius: $border-radius-lg-custom;
  }
}

.grecaptcha-badge { visibility: hidden; }
