.certificate {
  .form-btn {
    .btn {
      width: 25%;
    }
  }
  @include media-breakpoint-down(md) {
    .form-btn {
      .btn {
        width: 40%;
      }
    }
  }
}
.grade {
  .grade-message-box {
    border-radius: 8px 0px;
  }
}
.info-card {
  .tooltip-inner {
    @extend .rounded-2;
  }
}

.certificate-image {
  max-width: 500px;
}

.wizard-buttons {
  @include media-breakpoint-up(lg) {
    direction: rtl;

    body[dir="rtl"] & {
      direction: ltr;
    }
  }
}

//CERTIFICATE ---------------------------------------------------------------------------- START
@media print {
  @page {
    size: landscape;
  }
  .card {
    box-shadow: none !important;
  }
}

.certificate-wrapper {
  position: relative;
  height: 210mm;
  width: 297mm;
  margin: 0 auto;
  overflow: hidden;
}

.certificate {
  position: relative;
  &__logo {
    position: absolute;
    right: 60px;
    top: 60px;
    height: 60px;
  }
  &__title {
    padding: $spacer * 2 0;
  }
  &__text {
    font-weight: $font-weight-bold;
  }
  &__name,
  &__nin {
    color: $mowaamah-green;
    margin-bottom: 1rem;
  }
  &__course-name {
    // color: $mowaamah-green-dark;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
  }
  &__date-label {
    text-align: left;
    font-weight: $font-weight-bold;
  }
  &__date {
    color: $mowaamah-green;
    font-weight: $font-weight-bold;
    text-align: right;
    margin-right: $spacer;
  }
  &__number {
    // color: $mowaamah-green-dark;
    font-weight: $font-weight-bold;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
//CERTIFICATE ---------------------------------------------------------------------------- END

// ACCEPTED -----------------------------------START
.mowaamah-certificate {
  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }
}
//ACCEPTED ---------------------------------END
