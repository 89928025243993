.steps {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  width: 12.5%;
  z-index: 0;
}

.step:after {
  display: block;
  position: absolute;
  top: 25px;
  left: 60px;
  content: "";
  height: 4px;
  width: 100%;
  background: $gray-200;
  z-index: -1;

  body[dir=rtl] &{
    right: 60px;
    left: auto;
  }
}

.step--completed:after {
  display: block;
  position: absolute;
  top: 25px;
  left: 60px;
  content: "";
  height: 4px;
  width: 100%;
  background: var(--bs-success);
  z-index: -1;

  body[dir=rtl] &{
    right: 60px;
    left: auto;
  }

}

.step:last-of-type:after {
  display: none;
}

.step-text {
  border-radius: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: $white;
}

.step-text--uncompleted {
  background-color: $gray-500;
  width: 50px;
  height: 50px;
  font-size: 26px;
}
.step-text--completed {
  background-color: var(--bs-success);
  width: 50px;
  height: 50px;
  font-size: 26px;
}
.step-text--current {
  background-color: var(--bs-tertiary);
  width: 50px;
  height: 50px;
  font-size: 30px;
}
.step-label-text {
  text-align: center;
  font-weight: normal;
  color: var(--bs-dark);
  margin-top: 10px;
  @extend .fs-6;
}

.step-label-text--active {
  text-align: center;
  font-weight: bold;
  color: var(--bs-gray);
  margin-top: 10px;
  @extend .fs-6;
}

@include media-breakpoint-down(md) {
  .step {
    width: 20%;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .step-text {
    border-radius: 6px 0px;
  }
  .step-text--current {
    background-color: var(--bs-tertiary);
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
  }
  .step-text--uncompleted {
    background-color: $gray-500;
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
  }
  .step-text--completed {
    background-color: var(--bs-success);
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
  }
  .step:after {
    top: 15px;
    height: 2px;
    left: 40px;

    body[dir=rtl] &{
      right: 40px;
      left: auto;
    }
  }

  .step--completed:after {
    left: 40px;
  
    body[dir=rtl] &{
      right: 40px;
      left: auto;
    }
  
  }

  .step-label-text, .step-label-text--active{
    display: none !important;
  }
}
