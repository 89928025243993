.order {
  .current-order-icon {
    height: 27.69px;
  }
  .inner_card {
    display: grid !important;
  }
  @include media-breakpoint-down(md) {
    .current,
    .old {
      box-shadow: none !important;
      padding: 0px !important;
    }
  }

  @include media-breakpoint-up(md) {
    .inner_card {
      box-shadow: none !important;
      border-radius: 0;
      border-bottom: 1px solid $gray-200;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
