.fileName {
    color: rgb(0, 126, 234);

    &:hover {
        color: rgb(169, 189, 207);
    }
}

.size {
    color: rgb(186, 186, 186);
}